import PricingKeys from '../translationKeys/PricingPageKeys';

export const PricingTranslations = {
  [PricingKeys.preparing]: '(準備中)',
  [PricingKeys.order_now]: '今すぐ注文',
  [PricingKeys.get_free]: '無料でプレミアムパスを取得',
  [PricingKeys.get_for_free]: '無料で入手',
  [PricingKeys.current_plan]: '適用中のプラン',
  [PricingKeys.company_verified]: '企業認証完了',

  // Banner
  [PricingKeys.Banner.title]: 'マーケティングをシンプルでスマートに！',
  [PricingKeys.Banner.description]:
    '当社のサービスはマーケティングの成果を更に向上させます！',

  [PricingKeys.Verified.Error.already_verified]: `
    アカウントはすでにブランドまたは企業として確認済みです。`,
  [PricingKeys.Verified.Error.already_using]: `
    アカウントは既にこのプランを使用しています。 <br>
    <span>こちらをクリック</span>、サブスクリプションを表示するには。`,
  [PricingKeys.Verified.Error.under_review]: `
    アカウントは審査中です。<br>
    後でもう一度お試しください。`,
  [PricingKeys.Verified.Error
    .recently_rejected]: `あなたの申請は最近審査されました。<br>しばらくしてから再試行してください。`,

  // Left Col
  // General
  [PricingKeys.Left_Col.General.verification_req]: '確認が必要です',
  [PricingKeys.Left_Col.General.smart_search]: 'スマートサーチ',

  // Analytics & Data
  [PricingKeys.Left_Col.Analytics_Data.title]: '分析とデータ',

  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.title]: `
  クリエーター基本分析`,
  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.tooltip]: `
  これにはクリエーターのソーシャルネットワークアカ <br>
  ウントのチャネルとコンテンツの分析が含まれます。`,

  [PricingKeys.Left_Col.Analytics_Data.audience_analytics]:
    'オーディエンス分析',

  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.title]: '価格ロック解除',
  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.tooltip]: `
  この月額クォータを使用してクリエイターのサービ <br>
  スの価格をアンロックできます。 アンロック後、 <br>
  72時間経過すると価格が再度ロックされます。`,

  [PricingKeys.Left_Col.Analytics_Data.order_analytics]: '注文のデータ分析',

  [PricingKeys.Left_Col.Analytics_Data.creator_reviews]: 'クリエイターレビュー',

  // Orders
  [PricingKeys.Left_Col.Orders.title]: `注文＆クリエイター`,
  [PricingKeys.Left_Col.Orders.order_packages]:
    'クリエイターパッケージを注文する',

  [PricingKeys.Left_Col.Orders.message_creators]:
    '新しいクリエイターに注文を開始する前にメッセージを送信する',

  [PricingKeys.Left_Col.Orders.Order_Discount.title]: '注文割引',
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_1]: `
  割引は各注文の合計価格に適用されます。 <br>
  * アドオンアイテム、配送料、月額定期購読には適用されません。`,
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_2]: `
  割引はクリエイターとの各注文の合計価格に適用されます。<br>
  一部のアドオンアイテムやプラットフォームの定期購読には適用されません。`,
  [PricingKeys.Left_Col.Orders.Order_Discount.content]: `
  注文価格の<Tooltip><span>{{number}}</span></Tooltip>`,

  [PricingKeys.Left_Col.Orders.Track_Delivery.title]: `
  国際配送の追跡`,
  [PricingKeys.Left_Col.Orders.Track_Delivery.tooltip]: `
  国際配送の注文の追跡をサポートします。<br>
  国内配送の追跡は一部の国で利用可能です。<br><br>
  * 注意：実際の配送プロセスは行われないことをご注意ください。`,

  [PricingKeys.Left_Col.Orders.auto_translation]: '完全自動翻訳システム',

  [PricingKeys.Summary.basic]: `
    <li>無料ですがパワフル</li>
    <li>マーケティングの結果を向上させるためのすべての基本機能が含まれています</li>`,
  [PricingKeys.Summary.verified]: `
    <li>たった1~3分で認証完了</li>
    <li>グローバルなブランドとビジネスに最適</li>`,
  [PricingKeys.Summary.premium]: `
    <li>大容量</li>
    <li>確認が必要です</li>
    <li>スケーリングに焦点を当てたグローバル企業に最適</li>`,

  // Creator
  [PricingKeys.Creator.title]: 'クリエイターのための「0円」サービス手数料 🚀',

  [PricingKeys.Creator.h1]:
    'メガエボリューションは、世界で最も<b>クリエイターに優しいプラットフォーム</b>の一つであり、クリエイターに対して追加の<b>サービス手数料が一切ありません。</b>今すぐ、複雑な手続きなしで、便利なコラボレーションを通じて、より良いコンテンツ制作に集中できます！',
  [PricingKeys.Creator.h2]:
    'ファッション、テック、ビューティー、フード、アート、スポーツ、AI、音楽など、さまざまな分野のグローバルブランドとのコラボレーションを支援し、他のクリエイターと共に成長できるよう、メガエボリューションがサポートします。',
  [PricingKeys.Creator.h3]: `
    * バランスを引き出すために必要な小額のサードパーティトランザクション料 <br>
    （例：Paypal）は対象外です。`,

  // Modal
  [PricingKeys.Modal.title]: 'ブランド・企業の確認',
  [PricingKeys.Modal.estimate]: '推定1~2分',
  [PricingKeys.Modal.caution]:
    '* 誤った情報または不正確な情報は、確認の喪失またはアカウントの永久的な禁止につながる可能性があります。',
  [PricingKeys.Modal.toast]: 'アカウントが正常に確認されました！',

  [PricingKeys.Modal.Btn.proceed_final]: '最終のステップに進む',
  [PricingKeys.Modal.Btn.verify_email]: 'メールアドレスの確認',
  [PricingKeys.Modal.Btn.no_company_email]: '会社のメールアドレスがありません',
  [PricingKeys.Modal.Btn.verify_with_email]: 'メールで確認する',
  [PricingKeys.Modal.Btn.complete_verification]: '検証を完了',
  [PricingKeys.Modal.Btn.another_email]: '別のメールアドレスを使用',
  [PricingKeys.Modal.Btn.resend_code]: 'コードを再送信',

  [PricingKeys.Modal.Placeholder.firstname]: '名を入力してください',
  [PricingKeys.Modal.Placeholder.lastname]: '姓を入力してください',
  [PricingKeys.Modal.Placeholder.companyname]: '会社名を入力してください',
  [PricingKeys.Modal.Placeholder.companyUrl]:
    '会社のウェブサイトURLを入力してください（例: www.megaevolution.io）',
  [PricingKeys.Modal.Placeholder.companyUrl_sm]:
    '会社のウェブサイトURLを入力してください',
  [PricingKeys.Modal.Placeholder.companyEmail]:
    '会社のメールアドレスを入力してください',
  [PricingKeys.Modal.Placeholder.enter_code]:
    '受け取ったコードを入力してください',

  [PricingKeys.Modal.CompanyType.title]: '企業タイプを選択',
  [PricingKeys.Modal.CompanyType.option_1]: 'ブランド · ビジネス',
  [PricingKeys.Modal.CompanyType.option_2]: 'Eコマースストア',
  [PricingKeys.Modal.CompanyType.option_3]: 'マーケティングエージェンシー',
  [PricingKeys.Modal.CompanyType.option_4]: 'その他',

  [PricingKeys.Modal.JobPosition.title]: '職種を選択',
  [PricingKeys.Modal.JobPosition.option_1]: 'アシスタント',
  [PricingKeys.Modal.JobPosition.option_2]: 'C-Level · VP',
  [PricingKeys.Modal.JobPosition.option_3]: 'ディレクター',
  [PricingKeys.Modal.JobPosition.option_4]: 'エグゼクティブ',
  [PricingKeys.Modal.JobPosition.option_5]: '部門長',
  [PricingKeys.Modal.JobPosition.option_6]: '独立',
  [PricingKeys.Modal.JobPosition.option_7]: 'マネージャー · チームリーダー',
  [PricingKeys.Modal.JobPosition.option_8]: 'インターン',
  [PricingKeys.Modal.JobPosition.option_9]: 'チームメンバー',

  // Final
  [PricingKeys.Modal.Final.explain]: `
    <li>検証のために次のファイルのいずれかをアップロードしてください：事業登録証明書、税額請求書、従業員IDカード、または会社の存在を確認する他のサポートドキュメント。</li>
    <li>ファイルはJPG、JPEG、PNG、PDF形式で、解像度は少なくとも300dpiである必要があります。</li>`,
  [PricingKeys.Modal.Final.image_placeholder]:
    'JPG、JPEG、PNG、PDF形式のファイルをアップロードしてください。最大5MB。',
  [PricingKeys.Modal.Final.description_placeholder]:
    '会社についてと、プラットフォームを通じて宣伝しようとしている製品、サービス、またはブランドについて教えてください。',
  [PricingKeys.Modal.Final.spam_explain]:
    '* 見つからない場合は、スパムフォルダをご確認ください。',

  // Popup
  [PricingKeys.Modal.Popup.title]: 'フォームが正常に送信されました！',
  [PricingKeys.Modal.Popup.description]:
    '通常、審査には1〜3営業日かかります。審査後、結果を含む電子メール通知を受け取ります。',

  [PricingKeys.Modal.submission.toast]:
    '提出が完了しました！認証には通常1～3営業日かかります。<br>結果が準備でき次第、すぐにお知らせいたします。',

  // Error
  [PricingKeys.Modal.Error.required_field]: '必須項目',
  [PricingKeys.Modal.Error.select_option]: 'オプションを選択してください',
  [PricingKeys.Modal.Error.valid_url]: '有効なURLを入力してください。',
  [PricingKeys.Modal.Error.valid_email]:
    '有効なメールアドレスを入力してください',
  [PricingKeys.Modal.Error.email_validation]:
    '個人用のメールアドレスは使用できません（例: gmail.com、yahoo.comなどは許可されていません）',
  [PricingKeys.Modal.Error.already_verified]:
    'このメールアドレスは既に検証に使用されました',
  [PricingKeys.Modal.Error.already_sent_code]:
    'このメールアドレスにはすでにコードが送信されました。受信トレイを確認してください。',
  [PricingKeys.Modal.Error.invalid_code]: '無効なコード',
  [PricingKeys.Modal.Error.invalid_file_format]:
    '無効なファイル形式です。 ({{fileFormats}} をご利用ください)',
  [PricingKeys.Modal.Error.total_file_size]:
    '合計ファイルサイズ制限は最大 {{maxSize}} です',
  [PricingKeys.Modal.Error.send_max_files]:
    '最大 {{maxFiles}} ファイルを送信できます',
};
